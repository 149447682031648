import React, { useEffect, useState } from 'react';
import useAxiosInstances from '../../services/axiosconfig';
import { fixDateHandler } from "../ReceiptIntimation/ReceiptIntimation";
import { toast } from "react-toastify";
import ModalComponent from '../../Components/ModalComponent'
import Checkbox from '@mui/material/Checkbox';

const IndentApproval = () => {
    const { SolzonMain } = useAxiosInstances()
    const [indentData, setIndentData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const [modalContent, setModalContent] = useState([]);
    const [checked, setChecked] = useState("")

    useEffect(() => {
        fetchIndentData()
    }, [])

    const fetchIndentData = async () => {

        try {
            let result = await SolzonMain.get(`https://aisapi.solzon.in/api/Indent/PendingApproval?p_aup_cd=${localStorage.getItem("aup_cd")}`)
            console.log(result.data)
            setIndentData(result.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    const modalOpenHandler = (content) => {
        console.log(content)
        setModalContent(content);
        setIsModalOpen(true);
    };

    const approveIndent = async (indentNumber, indentQunatity) => {
        setChecked(indentNumber)
        try {
            let result = await SolzonMain.put(`https://aisapi.solzon.in/api/Indent/IndentApproval?p_ii_id=${indentNumber}&p_qty=${indentQunatity}`)
            toast.success(result.data.data)
            fetchIndentData()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="tableContainer">
            <table className="table">
                <thead>
                    <tr>
                        <th>Indent Number</th>
                        <th>Indent Date</th>
                        <th>Customer Name</th>
                    </tr>
                </thead>
                <tbody>
                    {indentData?.map((item) => {
                        return (
                            <tr onClick={() => modalOpenHandler(item.childIndent)}>
                                <td>{item.in_no}</td>
                                <td>{fixDateHandler(item.in_dt)}</td>
                                <td>{item.ac_nm}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
                <div className="modalStuff">
                    <div className="tableContainer">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Width</th>
                                    <th>Length</th>
                                    <th>Quantity</th>
                                    <th>Remarks</th>
                                    <th>Approve</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalContent?.map((content) => (
                                    <tr>
                                        <td>{content.mn_nm}</td>
                                        <td>{content.dw_nm}</td>
                                        <td>{content.dl_nm}</td>
                                        <td>{content.qty}</td>
                                        <td>{content.rmrk}</td>
                                        <td><Checkbox checked={checked === content.ii_id} onChange={() => approveIndent(content.ii_id, content.qty)} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalComponent>
        </div>
    )
}

export default IndentApproval;