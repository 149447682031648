import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import MainContainer from './Components/MainContainer/MainContainer'
import LoginPage from './pages/Login/LoginPage'
import IndentPage from './pages/Indent/View/IndentPage'
// import LoginPage from './pages/Login/LoginPage'
// import IndentPage from './pages/Indent/IndentPage'
import IndentBooking from './pages/Indent/IndentBooking'
import Dashboard from "./pages/Dashboard/Dashboard";
import ReceiptIntimation from "./pages/ReceiptIntimation/ReceiptIntimation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ViewReceiptIntimation from "./pages/ReceiptIntimation/ViewReceiptIntimation";
import DocumentApproval from "./pages/Document/DocumentApproval";
import Reports from './pages/Reports/ReportPage'
import { ToastContainer } from "react-toastify";
import { LoadingProvider } from './Components/Loader/LoadingContext'
import Profile from "./pages/Profile/Profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />
  },
  {
    path: "/",
    element: <MainContainer />,
    children: [
      {
        path: "/indentpage",
        element: <IndentPage />
      },
      {
        path: "/dashboard",
        element: <Dashboard />
      },
      {
        path: "/indentbooking",
        element: <IndentBooking />
      },
      {
        path: "/receiptintimation",
        element: <ReceiptIntimation />
      },
      {
        path: "/receiptintimationpage",
        element: <ViewReceiptIntimation />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/documentapproval",
        element: <DocumentApproval />
      },
      {
        path: "/reports",
        element: <Reports />
      }
    ],
  },
])

const queryClient = new QueryClient()

const App = () => {
  return (
    <>
      <LoadingProvider>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </LoadingProvider>
    </>
  );
};

export default App;
