import React, { useState } from "react";
import "./LoginPageStyle.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../Components/Loader/LoadingContext";
import Loader from "../../Components/Loader/LoaderComponent";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password)
      return toast.error("Please enter user Id or Password!");
    // setIsLoading(true);
    let reqObj = {
      aup_cd: username,
      aup_pin: password,
    };
    axios
      .post(`https://aisapi.solzon.in/api/User/Login`, reqObj)
      .then((response) => {
        console.log(response.data?.data);
        setIsLoading(false);
        if (response.data?.data?.returnMessage === "Valid User") {
          toast.success("Authorized");
          Navigate("/dashboard");
          localStorage.setItem("aup_cd", username);
          localStorage.setItem("au_typ", response.data?.data.aup_typ);
          localStorage.setItem("aup_nm", response.data?.data.aup_nm);
          localStorage.setItem("email", response.data?.data.email);
          localStorage.setItem("mbl", response.data?.data.mbl);
          localStorage.setItem("validToken", response.data?.data.validToken)
        } else {
          toast.error("Access denied");
          console.log("asd");
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error("Access denied");
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer />
      <section className={styles.mainContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.contentContainer}>
            <h1>Welcome Back!</h1>
            <p>Please login with your personal info</p>
          </div>
          <div className={styles.formConatiner}>
            <div>
              <h1>Login To </h1>
              <h1>
                <span
                  style={{
                    color: "#5783db",
                    fontWeight: "700",
                    letterSpacing: "2px",
                  }}
                >
                  Agent Information System
                </span>
              </h1>
            </div>
            <input
              type="email"
              placeholder="User Id"
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </section>
    </>

    // <div className="login-container">
    //   <h2>Agnet Information System</h2>
    //   <form onSubmit={handleLogin}>
    //     <div className="form-group">
    //       <label htmlFor="username">User Name</label>
    //       <input
    //         type="text"
    //         id="username"
    //         value={username}
    //         onChange={(e) => setUsername(e.target.value)}
    //       />
    //     </div>
    //     <div className="form-group">
    //       <label htmlFor="password">Password</label>
    //       <input
    //         type="password"
    //         id="password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //     </div>
    //     <button className="form-button" type="submit">
    //       Login
    //     </button>
    //   </form>
    //   <ToastContainer />
    // </div>
  );
};

export default LoginPage;
